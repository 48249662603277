import React from "react"
import {css} from "@emotion/core"
import {Link} from "gatsby"
import {useRecoilState} from "recoil"
import {menuState} from "../atoms"
import Theme from "../styles/theme"
import {VoormediaLogo} from "../icons"

const Header = (): JSX.Element => {
  const [openMenu, setOpenMenu] = useRecoilState(menuState)

  return (
    <div css={HeaderStyles(openMenu)}>
      <div className="header">
        <Link to="/" className="logo">
          {VoormediaLogo}
        </Link>
        <div
          onClick={() => setOpenMenu(!openMenu)}
          className={`burger ${openMenu ? "open" : ""}`}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  )
}

export default Header

const HeaderStyles = (openMenu: boolean) => css`
  position: fixed;
  z-index: 11;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.8rem 3rem;
    margin: 0 auto;

    @media ${Theme.breakpoints.desktop} {
      padding: 3rem 5.5rem;
    }
  }

  svg {
    max-width: 10.3rem;
    @media ${Theme.breakpoints.desktop} {
      max-width: 19.5rem;
    }

    g#homepage {
      fill: ${openMenu ? "#212121" : "white"};
      transition: fill 0.3s ease-in;
      transition-delay: 0.3s;
      @media ${Theme.breakpoints.desktop} {
        fill: white;
      }
    }
  }

  .burger {
    width: 2.1rem;
    height: 2.1rem;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: ${openMenu ? "#212121" : "#FFFFFF"};
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: ${openMenu
        ? "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out"
        : "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.4s"};
      -moz-transition: ${openMenu
        ? "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out"
        : "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.4s"};
      -o-transition: ${openMenu
        ? "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out"
        : "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.4s"};
      transition: ${openMenu
        ? "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out"
        : "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.4s"};
    }

    span:nth-of-type(1) {
      top: 0px;
    }

    span:nth-of-type(2),
    span:nth-of-type(3) {
      top: 7px;
    }

    span:nth-of-type(4) {
      top: 15px;
    }

    &.open span:nth-of-type(1) {
      top: 7px;
      width: 0%;
      left: 50%;
    }

    &.open span:nth-of-type(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.open span:nth-of-type(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &.open span:nth-of-type(4) {
      top: 7px;
      width: 0%;
      left: 50%;
    }
  }
`
