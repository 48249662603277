import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import {useEffect} from "react"
import {pageDescriptionState, mouseMovedState} from "../atoms"
import {useSetRecoilState} from "recoil"
import {StoryOverlay, HomepageOverlay} from "."

import {Story} from "../types"

interface Props {
  currentStory?: Story
}

const Overlay = (props: Props): JSX.Element => {
  const setMouseMoved = useSetRecoilState(mouseMovedState)
  const setPageDescription = useSetRecoilState(pageDescriptionState)

  let timeout: NodeJS.Timeout
  const onMouseMove = () => {
    setMouseMoved(true)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setMouseMoved(false)
    }, 1500)
    return () => clearTimeout(timeout)
  }

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove)

    return () => {
      document.removeEventListener("mousemove", onMouseMove)
    }
  }, [])

  useEffect(() => {
    setPageDescription(props.currentStory?.subjectProject)
  }, [props.currentStory])

  const data = useStaticQuery(graphql`
    query StoriesQuery {
      allDatoCmsStory {
        edges {
          node {
            title
            slug
            thumbnail {
              url
            }
            video {
              landscape
              portrait
              duration
              subtitles {
                url
              }
            }
            subjectPerson
            subjectProject
            description
            indexHighlights {
              from
              to
            }
            playOrder
          }
        }
      }
    }
  `)
  const stories: Story[] = data.allDatoCmsStory.edges
    .map((story: {node: Story}) => story.node)
    .sort((a: Story, b: Story) => a.playOrder - b.playOrder)

  if (props.currentStory) {
    return <StoryOverlay stories={stories} {...props} />
  } else {
    return <HomepageOverlay stories={stories} />
  }
}

export default Overlay
