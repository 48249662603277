import React from "react"
import {css} from "@emotion/core"
import {Link} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useRecoilValue, useRecoilState} from "recoil"
import {videoTimeState, watchedStoriesState} from "../atoms"
import Theme from "../styles/theme"

import {CountdownTimer} from "."

import {Story} from "../types"

interface Props {
  story: Story
  watchingNow?: boolean
  playingNext?: boolean
  hidden?: boolean
}

const StoryCta = (props: Props): JSX.Element => {
  const currentTime = useRecoilValue(videoTimeState)
  const [watchedStories, setWatchedStories] = useRecoilState(
    watchedStoriesState,
  )
  const watched = watchedStories.filter(
    (story: Story | null) => story?.slug === props.story.slug,
  ).length
  const finished = Math.floor(currentTime) === props.story.video.duration

  const duration = (time: number) => {
    return (
      <span className="time">
        {`${Math.floor(time / 60)}:${("0" + (time % 60)).slice(-2)}`}
      </span>
    )
  }

  return (
    <Link
      to={`/stories/${props.story.slug}`}
      css={StoryCtaStyles({
        watched: !!watched && !props.watchingNow,
        hidden: !!props.hidden,
      })}
      onClick={() => {
        setWatchedStories(
          watchedStories.filter(story => story.slug !== props.story.slug),
        )
      }}
    >
      <figure
        style={{
          backgroundImage: `url(${props.story.thumbnail.url}`,
        }}
      >
        {props.playingNext && <CountdownTimer seconds={5} />}
      </figure>
      <div css={CtaInfoStyles({watchingNow: !!props.watchingNow})}>
        <div css={AngleIconStyles}>
          <span className="status">
            {props.playingNext
              ? "playing next"
              : props.watchingNow
              ? finished
                ? "watched"
                : "watching"
              : watched
              ? "watched"
              : "watch now"}
          </span>
          <FontAwesomeIcon icon={["far", "angle-right"]} />
        </div>
        <h3>{props.story.title}</h3>
        <span className="subject">{props.story.subjectPerson}</span>
        <span className="subject">{props.story.subjectProject}</span>
        <span className="duration">
          <FontAwesomeIcon icon={["far", "stopwatch"]} />{" "}
          {props.watchingNow && (
            <>
              {duration(Math.floor(currentTime))}
              {" / "}
            </>
          )}
          {!props.watchingNow && !!watched && (
            <>
              {duration(props.story.video.duration)}
              {" / "}
            </>
          )}
          {duration(props.story.video.duration)}
        </span>
      </div>
    </Link>
  )
}

export default StoryCta

const StoryCtaStyles = (props: {watched: boolean; hidden: boolean}) => css`
  position: relative;
  display: flex;
  padding: 1.5rem 2.4rem 0;
  pointer-events: ${props.hidden ? "none" : "all"};
  filter: ${props.watched ? "grayscale(1)" : "none"};
  opacity: ${props.watched ? "0.8" : "1"};

  figure {
    position: relative;
    margin: 0 2rem 0 0;
    border-radius: 1rem;
    width: 8.5rem;
    height: 9rem;
    min-width: 8.5rem;
    min-height: 9rem;
    background-position: center;
    background-size: cover;
  }

  @media ${Theme.breakpoints.desktop} {
    justify-content: center;
    margin-bottom: 3rem;
    z-index: 2;

    figure {
      width: 10.5rem;
      height: 12rem;
      min-width: 10.5rem;
      min-height: 12rem;
    }
  }
`

const CtaInfoStyles = (props: {watchingNow: boolean}) => css`
  // 2x padding (2.4) + figure (8.5 + 1.5) + 0.2rem angle-right
  max-width: calc(100vw - 15.5rem);
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  h3 {
    margin: 0.2rem 0 0.4rem 0;
    line-height: 2rem;
    color: ${props.watchingNow ? "#68af61" : "#fff"};
  }

  span {
    display: block;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.2rem;
    line-height: 1.7rem;
    &.duration,
    &.status {
      font-size: 1rem;
    }
    &.status {
      text-transform: uppercase;
    }
    &.time {
      display: inline-block;
      width: 2.3rem;
      margin: 0 0.5rem;
    }
    &.subject {
      display: none;
    }
  }

  @media ${Theme.breakpoints.desktop} {
    width: 20rem;

    h3 {
      margin: 0;
      font-size: 2rem;
      line-height: 4.3rem;
    }

    span {
      font-size: 2rem;
      line-height: 2.1rem;
      &.duration,
      &.status {
        font-size: 1.4rem;
      }
      &.time {
        width: 3rem;
        font-size: 1.2rem;
      }
    }
  }
`

const AngleIconStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.75);
  }
`
