import React from "react"
import {css} from "@emotion/core"
import {useRecoilValue} from "recoil"
import {subtitlesState} from "../atoms"
import Theme from "../styles/theme"

const Subtitles = (): JSX.Element => {
  const subtitles = useRecoilValue(subtitlesState)

  return (
    <div css={SubtitleStyles}>
      {" "}
      {subtitles && subtitles.length > 0 && (
        <div className="subtitles">
          {subtitles.map((subtitle, index) => (
            <div className="subtitle" key={index}>
              {subtitle}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Subtitles

const SubtitleStyles = css`
  position: absolute;
  bottom: 19rem;
  transform: translateY(50%);
  width: 100%;
  @media ${Theme.breakpoints.notDesktop} {
    bottom: 22rem;
  }

  .subtitles {
    margin: 0 auto;
    padding: 1rem;
    font-family: Poppins;
    font-weight: 500;
    font-size: 3rem;
    line-height: 4rem;
    letter-spacing: 0;
    text-align: center;
    color: white;
    text-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 50%);

    @media ${Theme.breakpoints.notDesktop} {
      font-size: 1.5rem;
      line-height: 2.4rem;
      max-width: 95%;
    }
  }
`
