import React, {useMemo, useEffect} from "react"
import {css} from "@emotion/core"
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil"
import Hls from "hls.js"
import {
  videoTimeState,
  videoRefState,
  watchedStoriesState,
  homepageWatchedState,
  videoMutedState,
  subtitlesState,
} from "../atoms"

import {Video, Story} from "../types"
import {useOrientation} from "../hooks/orientation"

interface Props {
  video: Video
  story?: Story
}

const FullscreenVideo = (props: Props): JSX.Element => {
  const orientation = useOrientation()
  const muted = useRecoilValue(videoMutedState)
  const [videoRef, setVideoRef] = useRecoilState(videoRefState)
  const [watched, setWatched] = useRecoilState(watchedStoriesState)
  const setSubtitles = useSetRecoilState(subtitlesState)
  const setHomepageWatched = useSetRecoilState(homepageWatchedState)
  const setVideoTime = useSetRecoilState(videoTimeState)

  function loadSubtitles() {
    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", () => {
        showSubtitles()
      })
    }
  }

  function showSubtitles() {
    const track = videoRef.current?.textTracks[0]
    if (track) {
      track.mode = "hidden"
      track.oncuechange = (event: any) => {
        setSubtitles(event.currentTarget?.activeCues[0]?.text.split("\n"))
      }
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      const url = `${props.video[orientation]}#t=${
        videoRef.current?.currentTime || 0
      }`

      if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        // If HLS is natively supported, let the browser do the work
        videoRef.current.src = url
        videoRef.current.addEventListener("loadedmetadata", () => {
          showSubtitles()
          videoRef.current?.play()
        })
      } else if (Hls.isSupported()) {
        // If the browser supports MSE, use hls.js to play the video
        const hls = new Hls({
          xhrSetup: xhr => {
            xhr.withCredentials = true
          },
          startPosition: videoRef.current?.currentTime || 0,
        })

        hls.loadSource(url)
        hls.attachMedia(videoRef.current)
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          loadSubtitles()
          videoRef.current?.play()
        })
      }

      const interval = setInterval(() => {
        if (videoRef.current && videoRef.current.currentTime > 0)
          setVideoTime(videoRef.current.currentTime)
      }, 500)
      return () => clearInterval(interval)
    }
  }, [videoRef])

  return useMemo(
    () => (
      <React.Fragment>
        <video
          css={VideoStyles}
          ref={current => setVideoRef({current, muted: true})}
          muted={muted}
          crossOrigin="anonymous"
          controls={false}
          autoPlay={true}
          playsInline={true}
          loop={false}
          onEnded={() => {
            if (
              props.story &&
              !watched.filter(story => story.slug === props.story?.slug).length
            ) {
              setWatched(watched.concat([props.story]))
            } else {
              setHomepageWatched(true)
            }
          }}
        >
          {" "}
          <track
            label="English"
            kind="metadata"
            srcLang="en"
            default
            src={props.video.subtitles.url}
          />
        </video>
      </React.Fragment>
    ),
    [orientation],
  )
}

export default FullscreenVideo

const VideoStyles = css`
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  &::cue {
    font-size: 1.8rem;
  }
`
