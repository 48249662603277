import React from "react"
import {css} from "@emotion/core"
import Theme from "../styles/theme"

interface Props {
  seconds: number
}

const CountdownTimer = (props: Props): JSX.Element => {
  return (
    <div css={CountdownTimerStyles}>
      <div>
        <div css={NumberStyles(props.seconds)}>
          {[...Array(props.seconds + 1)].map((_, i) => (
            <span key={i}>{props.seconds - i}</span>
          ))}
        </div>
        <div css={CircleStyles(props.seconds)}>
          <svg>
            <circle r="27" cx="30" cy="30" />
            <circle r="27" cx="30" cy="30" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default CountdownTimer

const CountdownTimerStyles = css`
  position: absolute;
  z-index: 30;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
    border-radius: 1rem;
  }
`
const CircleStyles = (seconds: number) => css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    width: 6rem;
    height: 6rem;
    transform: rotateY(-180deg) rotateZ(-90deg);

    > circle:first-of-type {
      stroke-dasharray: 169.5px;
      stroke-dashoffset: -169.5px;
      stroke-linecap: round;
      stroke-width: 5px;
      stroke: white;
      fill: none;
      animation: ${`countdown ${seconds}s linear 1 forwards`};
    }

    > circle:last-of-type {
      stroke-dasharray: 169.5px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 5px;
      stroke: rgba(255, 255, 255, 0.2);
      fill: none;
    }
  }

  @keyframes countdown {
    from {
      stroke-dashoffset: -169.5px;
    }
    to {
      stroke-dashoffset: 0px;
    }
  }
`

const NumberStyles = (seconds: number) => css`
  display: inline-block;
  height: 3rem;
  width: 3rem;
  overflow: hidden;
  text-align: center;

  > span {
    display: block;
    height: 3rem;
    margin: 0 auto;
    color: #fff;
    font-size: 3rem;
    animation: ${`seconds ${seconds}s 1 step-end forwards`};
  }

  @keyframes seconds {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-3rem);
    }
    40% {
      transform: translateY(-6rem);
    }
    60% {
      transform: translateY(-9rem);
    }
    80% {
      transform: translateY(-12rem);
    }
    100% {
      transform: translateY(-15rem);
    }
  }
`
