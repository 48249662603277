import React, {useState, useEffect} from "react"
import {css} from "@emotion/core"
import {useRecoilValue} from "recoil"
import {homepageWatchedState, videoTimeState} from "../atoms"

import {MuteButton, StoryCta, StoryMenu, Subtitles, VideoControls} from "."
import Theme from "../styles/theme"
import {Story} from "../types"

import {
  OverlayStyles,
  CallToActionStyles,
  DynamicCallToActionStyles,
  StoryMenuWrapperStyles,
  BlurredOverlayStyles,
} from "../styles/overlay-styles"

interface Props {
  stories: Story[]
}

const HomepageOverlay = (props: Props): JSX.Element => {
  const currentTime = useRecoilValue(videoTimeState)
  const homepageWatched = useRecoilValue(homepageWatchedState)
  const [showingInfo, setShowingInfo] = useState<boolean>(false)
  const [showingControls, setShowingControls] = useState<boolean>(false)
  const [storyMenuOpen, setStoryMenuOpen] = useState<boolean>(false)
  const [currentCta, setCurrentCta] = useState<Story | null>(null)
  const [previousCta, setPreviousCta] = useState<Story | null>(null)
  const showCtasFrom = Math.min(
    ...props.stories
      .map(story => story.indexHighlights.map(highlight => highlight.from))
      .flat(),
  )

  useEffect(() => {
    let ctaToShow: Story | null = null
    // Show links to stories on the homepage
    props.stories.forEach(story => {
      story.indexHighlights.forEach(highlight => {
        if (highlight.from < currentTime && highlight.to > currentTime) {
          ctaToShow = story
        }
      })
    })
    if (currentTime < showCtasFrom) setCurrentCta(null)
    if (ctaToShow) {
      if (currentCta !== ctaToShow) {
        setPreviousCta(currentCta)
      }
      setCurrentCta(ctaToShow)
    }
    setShowingInfo(showCtasFrom < currentTime)
  }, [currentTime])

  return (
    <div
      css={OverlayStyles}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setShowingControls(!showingControls)
        }
      }}
    >
      <MuteButton />
      <VideoControls
        active={!storyMenuOpen && showingControls}
        watched={homepageWatched}
      />

      <div css={TitleStyles({visible: !currentCta, controls: showingControls})}>
        <h1>Taking your business to the next level</h1>
      </div>

      <div
        css={[
          CallToActionStyles,
          BlurredOverlayStyles({
            transparent: !showingInfo && !storyMenuOpen,
          }),
        ]}
      >
        <div css={DynamicCallToActionStyles}>
          {props.stories.map(story => (
            <div
              key={story.slug}
              css={CtaWrapperStyles({
                current: story === currentCta,
                previous: story === previousCta,
                hidden: storyMenuOpen,
              })}
            >
              <StoryCta story={story} />
            </div>
          ))}
        </div>
        <div className="footer">
          <MuteButton />
          <div css={StoryMenuWrapperStyles({visible: true})}>
            <StoryMenu
              open={storyMenuOpen}
              setOpen={setStoryMenuOpen}
              {...props}
            />
          </div>
          <Subtitles />
        </div>
      </div>
    </div>
  )
}

export default HomepageOverlay

const TitleStyles = (props: {visible: boolean; controls: boolean}) => css`
  position: absolute;
  display: flex;
  top: 30vh;
  left: 0;
  right: 0;
  color: #fff;
  justify-content: center;
  text-align: center;
  transition: 0.5s opacity ease-in-out;
  pointer-events: none;

  @media ${Theme.breakpoints.desktop} {
    opacity: ${props.visible ? "1" : "0"};
  }
  @media ${Theme.breakpoints.notDesktop} {
    opacity: ${props.visible && !props.controls ? "1" : "0"};
  }

  @media ${Theme.breakpoints.medium} {
    max-width: 112rem;
    margin: 0 auto;
  }

  h1 {
    max-width: 24rem;
    font-weight: 500;
    text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 2.6rem;

    @media ${Theme.breakpoints.medium} {
      max-width: 54rem;
      line-height: 8rem;
      font-size: 7rem;
    }
  }
`

const CtaWrapperStyles = (props: {
  current: boolean
  previous: boolean
  hidden: boolean
}) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${props.current ? "0" : props.previous ? "-100vw" : "100vw"};
  right: ${props.current ? "0" : props.previous ? "100vw" : "-100vw"};
  opacity: ${props.current && !props.hidden ? "1" : "0"};
  transition: opacity 0.5s ease-in-out, left 0.8s ease-in-out,
    right 0.8s ease-in-out;

  @media ${Theme.breakpoints.desktop} {
    left: ${props.current
      ? "calc(100vw - 40.2rem)" // TODO: We don't want it fixed here, but using `unset` breaks transitions
      : props.previous
      ? "-100vw"
      : "100vw"};
    right: ${props.current ? "3rem" : props.previous ? "100vw" : "-100vw"};

    > a {
      bottom: calc(-100vh + 40rem);
      padding: 2rem;
      backdrop-filter: ${props.current ? "blur(8px)" : "none"};
      border-radius: 1rem;
    }
  }
`
