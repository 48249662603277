import React, {useState, useEffect} from "react"
import {css, SerializedStyles} from "@emotion/core"
import {useRecoilValue} from "recoil"
import {mouseMovedState, videoTimeState, watchedStoriesState} from "../atoms"

import {MuteButton, StoryInfo, StoryMenu, Subtitles, VideoControls} from "."

import {Story} from "../types"

import Theme from "../styles/theme"
import {
  OverlayStyles,
  CallToActionStyles,
  StoryMenuWrapperStyles,
  BlurredOverlayStyles,
} from "../styles/overlay-styles"

interface Props {
  currentStory?: Story
  stories: Story[]
}

const StoryOverlay = (props: Props): JSX.Element => {
  const currentTime = useRecoilValue(videoTimeState)
  const watched = useRecoilValue(watchedStoriesState)
  const [storyMenuOpen, setStoryMenuOpen] = useState<boolean>(false)
  const mouseMoved = useRecoilValue(mouseMovedState)
  const [dynamicallyShowingInfo, setDynamicallyShowingInfo] = useState<boolean>(
    true,
  )
  const [manuallyShowingInfo, setManuallyShowingInfo] = useState<boolean>(false)

  useEffect(() => {
    // Potentially show the title of the current story
    setDynamicallyShowingInfo(currentTime < 5)
  }, [currentTime])

  const showingInfo = dynamicallyShowingInfo || manuallyShowingInfo
  const desktopShowingInfo = dynamicallyShowingInfo || mouseMoved

  return (
    <div
      css={[OverlayStyles, BlurredOverlayStyles({transparent: !storyMenuOpen})]}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setManuallyShowingInfo(!manuallyShowingInfo)
        }
      }}
    >
      <div
        css={LinearGradientStyles({
          transparent: !showingInfo && !storyMenuOpen,
          desktopTransparent: !desktopShowingInfo && !storyMenuOpen,
        })}
      />
      <MuteButton />
      <VideoControls
        active={manuallyShowingInfo && !storyMenuOpen}
        watched={
          !!watched.find(story => story.slug === props?.currentStory?.slug)
        }
      />

      <div css={CallToActionStyles}>
        <StoryInfo
          story={props.currentStory}
          desktopVisible={desktopShowingInfo && !storyMenuOpen}
          visible={showingInfo && !storyMenuOpen}
          highlighted={currentTime < 6}
        />

        <div css={StoryMenuWrapperStyles({visible: true})}>
          <StoryMenu
            open={storyMenuOpen}
            setOpen={setStoryMenuOpen}
            {...props}
          />
        </div>

        <Subtitles />
      </div>
    </div>
  )
}

export default StoryOverlay

// It's not possible to transition a linear-gradient, so we put it
// in its own div and transition its opacity instead
const LinearGradientStyles = (props: {
  transparent: boolean
  desktopTransparent: boolean
}): SerializedStyles => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 58.7%,
    rgba(0, 0, 0, 0.4) 100%
  );

  @media ${Theme.breakpoints.notDesktop} {
    opacity: ${props.transparent ? "0" : "1"};
  }

  @media ${Theme.breakpoints.desktop} {
    opacity: ${props.desktopTransparent ? "0" : "1"};
  }
`
