import React, {useEffect} from "react"
import {css} from "@emotion/core"
import {Global} from "@emotion/core"
import emotionNormalize from "emotion-normalize"
import {fontawesome} from "./fontawesome"
import {Typography} from "../styles/typography"
import Theme from "../styles/theme"
import "fontsource-poppins/300.css"
import "fontsource-poppins/500.css"
import "fontsource-poppins/600.css"

export const GlobalStyles = (): JSX.Element => {
  return (
    <Global
      styles={css`
        ${emotionNormalize}
        ${fontawesome}
        ${Styles}
        ${Typography}
      `}
    />
  )
}

const Styles = css`
  html {
    font-size: 10px;
  }

  body {
    width: 100vw;
    height: 200vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #000;
  }

  * {
    font-family: "Poppins";
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  input[type="submit"] {
    font-size: 1.8rem;
    color: ${Theme.colors.fontcolors.secondary};
    background-color: ${Theme.colors.buttoncolors.light};
    border: 0.8rem solid ${Theme.colors.buttoncolors.grayBorder};
    padding: 1.7rem;
  }

  .separator {
    background-color: ${Theme.colors.backgrounds.orange};
    height: 0.2rem;
    width: 100%;
    max-width: 4.7rem;
  }
`
