import React, {useEffect} from "react"
import {css} from "@emotion/core"
import {Helmet} from "react-helmet"
import {useRecoilValue} from "recoil"
import {pageDescriptionState} from "../atoms"
import {Header, Menu} from "."

import {GlobalStyles} from "../styles/global-styles"
import "fontsource-poppins"

interface Props {
  title?: string
  children?: React.ReactNode
}

const Layout = (props: Props): JSX.Element => {
  const pageDescription = useRecoilValue(pageDescriptionState)
  const defaultDescription =
    "We create websites, produce video and build applications. Our expertise and software enables companies to serve customers online."

  useEffect(() => {
    // Hides tab bar on mobile safari
    window.scrollTo(0, 99999)
  }, [])

  return (
    <>
      <Helmet>
        <title>Voormedia{props.title ? ` - ${props.title}` : ""}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <meta
          content={pageDescription ? pageDescription : defaultDescription}
          name="description"
        />
        <meta
          content="Shaping your online identity"
          name="twitter:title"
          property="og:title"
        />
        <meta
          content={pageDescription ? pageDescription : defaultDescription}
          name="twitter:description"
          property="og:description"
        />
        <meta
          content="https://75d87dnb.tinifycdn.com/images/thumbs/responsive/responsive-2x-9405a4ea.jpg"
          name="twitter:image:src"
          property="og:image"
        />
        <meta content="website" property="og:type" />
        <meta content="http://voormedia.com/" property="og:url" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="@voormedia" name="twitter:site" />
      </Helmet>
      <GlobalStyles />

      <Menu />
      <Header />
      <main css={MainStyles}>{props.children}</main>
    </>
  )
}

export default Layout

const MainStyles = css`
  display: block;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
`
