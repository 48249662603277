import React, {useState, useEffect, useRef} from "react"
import {css} from "@emotion/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useRecoilValue} from "recoil"
import {mouseMovedState, videoRefState} from "../atoms"
import Theme from "../styles/theme"
import {PulseStyles} from "../styles/overlay-styles"
import {ForwardIcon, RewindIcon, IconStyles} from "../icons"

interface Props {
  active: boolean
  watched: boolean
}

const VideoControls = ({active, watched}: Props): JSX.Element | null => {
  const videoRef = useRecoilValue(videoRefState)
  const [paused, setPaused] = useState<boolean>(!!videoRef.current?.paused)
  const [buttonClicked, setButtonClicked] = useState<string | undefined>()
  const mouseMoved = useRecoilValue(mouseMovedState)
  const [mouseInside, setMouseInside] = useState<boolean>(false)
  const controls = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (controls.current) {
      controls.current.addEventListener("mouseenter", () =>
        setMouseInside(true),
      )
      controls.current.addEventListener("mouseleave", () =>
        setMouseInside(false),
      )
    }
  }, [controls])

  useEffect(() => {
    setPaused(!!videoRef.current?.paused)
  }, [videoRef.current?.paused])

  useEffect(() => {
    if (buttonClicked) {
      const timeout = setTimeout(() => {
        setButtonClicked(undefined)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [buttonClicked])

  return (
    <div
      css={VideoControlsStyles({
        active: !watched && active,
        desktopActive: !watched && (mouseMoved || mouseInside || paused),
      })}
      ref={controls}
    >
      <div
        css={PulseStyles(buttonClicked === "rewind")}
        onClick={() => {
          if (videoRef.current) {
            videoRef.current.currentTime -= 5
            setButtonClicked("rewind")
          }
        }}
      >
        {RewindIcon}
      </div>

      <div
        css={[
          IconStyles,
          PulseStyles(buttonClicked === "play/pause"),
          PlayIconStyles,
        ]}
      >
        <FontAwesomeIcon
          onClick={() => {
            if (videoRef.current) {
              if (videoRef.current.paused) {
                setPaused(false)
                videoRef.current.play()
              } else {
                setPaused(true)
                videoRef.current.pause()
              }
              setButtonClicked("play/pause")
            }
          }}
          icon={["fas", paused ? "play" : "pause"]}
        />
      </div>

      <div
        css={PulseStyles(buttonClicked === "forward")}
        onClick={() => {
          if (videoRef.current) {
            videoRef.current.currentTime += 5
            setButtonClicked("forward")
          }
        }}
      >
        {ForwardIcon}
      </div>
    </div>
  )
}

export default VideoControls

const VideoControlsStyles = (props: {
  active: boolean
  desktopActive: boolean
}) => css`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 50%;
  transition: 0.5s opacity ease-in-out;

  @media ${Theme.breakpoints.notDesktop} {
    top: calc(50vh - 8rem);
    opacity: ${props.active ? "1" : "0"};
    pointer-events: ${props.active ? "all" : "none"};
  }

  @media ${Theme.breakpoints.desktop} {
    width: 20rem;
    height: 6.1rem;
    left: 5.3rem;
    bottom: 6.5rem;
    justify-content: space-between;
    opacity: ${props.desktopActive ? "1" : "0"};
    pointer-events: ${props.desktopActive ? "all" : "none"};
  }
`

const PlayIconStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
