import {atom, selector, DefaultValue} from "recoil"
import {recoilPersist} from "recoil-persist"
import {Story} from "../types"

interface VideoState {
  current?: HTMLVideoElement | null
  muted?: boolean
}

const persist =
  typeof sessionStorage !== `undefined`
    ? recoilPersist({
        key: "recoil-persist",
        storage: sessionStorage,
      })
    : null

export const videoRefState = atom<VideoState>({
  key: "videoRef",
  default: {},
})

const videoMutedInternal = atom<boolean>({
  key: "videoMutedInternal",
  default: true,
  effects_UNSTABLE: persist?.persistAtom ? [persist.persistAtom] : undefined,
})

export const videoMutedState = selector<boolean>({
  key: "videoMuted",
  get: ({get}) => get(videoMutedInternal),
  set: ({get, set}, newValue: boolean | DefaultValue) => {
    const muted = newValue instanceof DefaultValue ? true : newValue
    const {current} = get(videoRefState)
    if (current) current.muted = muted
    set(videoMutedInternal, muted)
  },
})

export const videoTimeState = atom<number>({
  key: "videoTime",
  default: 0,
})

export const homepageWatchedState = atom<boolean>({
  key: "watchedHomepage",
  default: false,
})

export const watchedStoriesState = atom<Story[]>({
  key: "watchedStories",
  default: [],
  effects_UNSTABLE: persist?.persistAtom ? [persist.persistAtom] : undefined,
})

export const subtitlesState = atom<string[] | undefined>({
  key: "subtitles",
  default: undefined,
})

export const pageDescriptionState = atom<string | undefined>({
  key: "pageDescription",
  default: undefined,
})

// Reset watched stories state when the browser is
// refreshed or closed explicitly
// NOTE: video muted state is also persisted!
if (typeof window !== `undefined`) {
  window.onbeforeunload = function () {
    sessionStorage.clear()
  }
}
