import React, {useEffect, useState} from "react"
import {css} from "@emotion/core"
import {useRecoilValue} from "recoil"
import {watchedStoriesState, homepageWatchedState} from "../atoms"
import {navigate} from "gatsby"

import {Story} from "../types"
import Theme from "../styles/theme"

import {StoryCta} from "."

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentStory?: Story
  stories: Story[]
}

const StoryMenu = (props: Props): JSX.Element => {
  const watched = useRecoilValue(watchedStoriesState)
  const homepageWatched = useRecoilValue(homepageWatchedState)
  const [playingNext, setPlayingNext] = useState<Story | null>(null)

  useEffect(() => {
    if (
      watched.filter(story => story.slug === props.currentStory?.slug).length
    ) {
      props.setOpen(true)

      if (props.currentStory) {
        setPlayingNext(nextStory(props.currentStory))
      } else {
        setPlayingNext(
          props.stories.find(story => story.playOrder === 0) || null,
        )
      }
    }
  }, [watched])

  useEffect(() => {
    if (homepageWatched) {
      props.setOpen(true)
      setPlayingNext(props.stories.find(story => story.playOrder === 0) || null)
    }
  }, [homepageWatched])

  useEffect(() => {
    if (playingNext) {
      const timeout = setTimeout(() => {
        navigate(`/stories/${playingNext.slug}`)
      }, 5000)
      return () => clearTimeout(timeout)
    }
  }, [playingNext])

  const nextStory = (current: Story): Story | null => {
    let next: Story | null = props.stories[props.stories.indexOf(current) + 1]
    if (watched.length === props.stories.length) return null
    if (!next) next = props.stories[0]

    if (next && watched.filter(story => story.slug === next?.slug).length) {
      return nextStory(next)
    } else {
      return next
    }
  }

  return (
    <div css={StoryMenuStyles({open: props.open})}>
      <div css={StoryOptionsStyles({open: props.open})}>
        <div className="scroll-container">
          {props.stories.map(story => (
            <StoryCta
              key={story.slug}
              story={story}
              hidden={!props.open}
              watchingNow={story.slug === props.currentStory?.slug}
              playingNext={story.slug === playingNext?.slug}
            />
          ))}
        </div>
      </div>

      <a css={ButtonStyles} onClick={() => props.setOpen(!props.open)}>
        {props.open ? "Close" : "See all our stories"}
      </a>
    </div>
  )
}

export default StoryMenu

const StoryMenuStyles = (props: {open: boolean}) => css`
  height: ${props.open ? "calc(100vh - 6.7rem)" : "7.5rem"};
  height: ${props.open ? "calc((var(--vh, 1vh) * 100) - 6.7rem)" : "7.5rem"};
  transition: height 0.5s ease-in-out;
`

const StoryOptionsStyles = (props: {open: boolean}) => css`
  height: ${props.open ? "calc(100vh - 6.7rem - 7.5rem)" : "0"};
  height: ${props.open
    ? "calc((var(--vh, 1vh) * 100) - 6.7rem - 7.5rem)"
    : "0"};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: height 0.5s ease-in-out, margin-top 0.5s ease-in-out;
  pointer-events: none;

  .scroll-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    @media ${Theme.breakpoints.notDesktop} {
      > a:first-of-type {
        padding-top: 0;
      }
    }
  }

  @media ${Theme.breakpoints.desktop} {
    display: flex;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    opacity: ${props.open ? "1" : "0"};
    transition: opacity 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out,
      background-color 0.5s ease-in-out;
    backdrop-filter: ${props.open ? "blur(8px)" : "none"};
    background-color: ${props.open ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0)"};

    .scroll-container {
      margin: auto;
      height: fit-content;
      width: fit-content;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 96rem;

      > a {
        flex-basis: calc(50% - 10rem);
      }
    }
  }
`

const ButtonStyles = css`
  display: block;
  padding: 1rem 4rem;
  text-decoration: none;
  box-shadow: 0 0 0px 4px rgb(151 151 151 / 10%);
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin: 2.2rem 7rem 2.2rem 2rem;
  pointer-events: all;
  background-color: rgb(255 255 255 / 10%);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;

  @media ${Theme.breakpoints.desktop} {
    position: absolute;
    right: 11.3rem;
    bottom: 4.5rem;
    width: 20rem;
    height: 5.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 2.2rem;
    line-height: 2.8rem;
    font-size: 1.8rem;
    box-shadow: 0 0 0px 6px rgb(151 151 151 / 10%);
  }

  &:hover {
    background-color: rgb(255 255 255 / 20%);
  }
`
