import {css, SerializedStyles} from "@emotion/core"
import Theme from "../styles/theme"

export const OverlayStyles = css`
  position: fixed;
  padding-top: 6.7rem;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${Theme.breakpoints.desktop} {
    padding: 3rem 5.5rem;
  }
`

export const CallToActionStyles = css`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  @media ${Theme.breakpoints.notDesktop} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
  }

  @media ${Theme.breakpoints.desktop} {
    top: 0;
    z-index: 0;
  }
`

export const DynamicCallToActionStyles = css`
  position: relative;
  height: 10.5rem;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: margin-top 0.5s ease-in-out;

  @media ${Theme.breakpoints.desktop} {
    height: 0;
  }
`

export const StoryMenuWrapperStyles = (props: {
  visible: boolean
}): SerializedStyles => css`
  opacity: ${props.visible ? "1" : "0"};
  pointer-events: ${props.visible ? "all" : "none"};
  transition: opacity 0.5s ease-in-out;
`

export const PulseStyles = (active: boolean): SerializedStyles => css`
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    }
    70% {
      -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  border-radius: 50%;
  animation: ${active ? "pulse 1s 1 ease-out" : "none"};
`

export const BlurredOverlayStyles = (props: {
  transparent: boolean
}): SerializedStyles => css`
  @media ${Theme.breakpoints.notDesktop} {
    transition: backdrop-filter 0.5s ease-in-out,
      background-color 0.5s ease-in-out, top 0.5s ease-in-out;

    background-color: ${props.transparent
      ? "rgba(0, 0, 0, 0)"
      : "rgba(0, 0, 0, 0.3)"};
    backdrop-filter: ${props.transparent ? "blur(0px)" : "blur(8px)"};
  }
`
