import {css} from "@emotion/core"
import React from "react"
import {Link} from "gatsby"
import {useRecoilState} from "recoil"
import {menuState} from "../atoms"
import Theme from "../styles/theme"

const Menu = (): JSX.Element => {
  const [openMenu] = useRecoilState(menuState)

  return (
    <div css={MenuStyles} className={openMenu ? "open" : ""}>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/jobs">Jobs</Link>
        </li>
      </ul>
    </div>
  )
}

export default Menu

const MenuStyles = css`
  position: fixed;
  z-index: 10;
  height: 0;
  transition: width 0.5s ease-out, background 1s ease-out;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

  @media ${Theme.breakpoints.desktop} {
    width: 0;
    max-width: 40rem;
    height: 100vh;
    left: auto;
    right: 0;
  }

  &.open {
    height: 100vh;
    transition: width 0.3s ease-in, background 0.5s ease-in;
    transition-delay: 0.1s;
    transform: translateZ(0); // Fix for iOS Safari in fullscreen landscape

    @media ${Theme.breakpoints.desktop} {
      width: 100%;
    }

    li {
      transform: scale(1) translateY(0px);
      opacity: 1;

      &:nth-of-type(1) {
        transition-delay: 0.27s;
      }
      &:nth-of-type(2) {
        transition-delay: 0.34s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.41s;
      }
    }
  }

  ul {
    position: relative;
    display: block;
    padding: 0px 48px 0;
    list-style: none;
    margin-top: 112px;

    li {
      margin-top: 5px;
      transform: scale(1.15) translateY(-30px);
      opacity: 0;
      transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
        opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      &:nth-of-type(1) {
        transition-delay: 0.36s;
      }
      &:nth-of-type(2) {
        transition-delay: 0.43s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.5s;
      }
      a {
        display: block;
        margin-bottom: 35px;
        position: relative;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        width: 100%;
        text-align: center;
      }
    }
  }
`
