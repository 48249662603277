import {css} from "@emotion/core"

export const Typography = css`
  h1 {
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 3.9rem;
  }

  h3 {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 2.6rem;
  }
`
