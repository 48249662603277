import React from "react"
import {css} from "@emotion/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useRecoilState} from "recoil"
import {videoMutedState} from "../atoms"
import Theme from "../styles/theme"
import {PulseStyles} from "../styles/overlay-styles"

const MuteButton = (): JSX.Element => {
  const [muted, setMuted] = useRecoilState(videoMutedState)

  return (
    <div
      css={[MuteButtonStyles, PulseStyles(!muted)]}
      onClick={() => setMuted(!muted)}
    >
      <FontAwesomeIcon icon={["far", muted ? "volume-mute" : "volume"]} />
    </div>
  )
}

export default MuteButton

const MuteButtonStyles = css`
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1.5rem;
  bottom: 1.7rem;
  width: 3.8rem;
  height: 3.8rem;
  cursor: pointer;
  font-size: 2.1rem;
  color: #fff;
  padding: 1rem;
  border-radius: 50%;
  line-height: 0;
  box-shadow: 0 0 0px 4px rgb(151 151 151 / 10%);
  background-color: rgb(255 255 255 / 10%);
  &:focus {
    outline: none;
  }

  @media ${Theme.breakpoints.desktop} {
    right: 5.3rem;
    bottom: 6.5rem;
    width: 6.1rem;
    height: 6.1rem;
    box-shadow: 0 0 0px 6px rgb(151 151 151 / 10%);
    font-size: 3rem;
  }
`
